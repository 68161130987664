<template>
  <a-modal id="suggestion-question-modal" v-model:open="showConfirmModal" centered>
    <p class="px-2 py-3">
      Do you want to answer a few questions and generate improvement recommendation?
    </p>
    <template #footer>
      <a-space class="d-flex w-100">
        <a-button @click="showConfirmModal = false"> No </a-button>
        <a-button type="primary" @click="handleConfirmImprovement"> Yes </a-button>
      </a-space>
    </template>
  </a-modal>

  <div
    v-if="loading && !isDemoOngoing"
    class="h-100 d-flex align-items-center justify-content-center"
  >
    <a-spin size="large" />
  </div>

  <div v-else id="sd-result-time-and-motion-study" class="px-4">
    <!-- Cycle details -->
    <a-row>
      <a-col :span="24" class="mb-2">
        <a-space class="d-flex justify-content-between align-items-center w-100">
          <a-typography-title :level="4">Time and Motion Study Result</a-typography-title>
          <div class="d-flex">
            <a-button
              type="primary"
              :loading="cycleDownloadLoading"
              class="me-1"
              @click="handleDownloadCycle"
            >
              <template #icon>
                <DownloadOutlined />
              </template>
              {{ downloadCycleLabels[downloadStatus] }}</a-button
            >
            <a-dropdown>
            <a-button type="primary" :loading="isDownloading">
              Download Excel
              <DownOutlined />
            </a-button>
            <template #overlay>
              <a-menu @click="downloadExcelSheet">
                <a-menu-item v-for="item in downloadExcelMenuItems" :key="item.key">{{
                  item.label
                }}</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          </div>
        </a-space>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-table
          id="cycle-result-table"
          :bordered="true"
          :title="null"
          :dataSource="[cyclesTableData]"
          :columns="cyclesTableColumn"
          size="small"
          class="mb-3"
          :scroll="tableScroll"
          :pagination="false"
        ></a-table>
      </a-col>
    </a-row>

    <!-- Charts -->
    <a-row :gutter="[24, 24]" class="mt-2">
      <a-col span="12">
        <a-card id="step-times-chart" title="Yamazumi Chart" size="small" hoverable>
          <BarChart
            :title="'Bar chart'"
            :data="!isDemoOngoing ? stepsTimePerCycle : stationDesignDummyObject.stepsTimePerCycle"
          />
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card id="value-pie-chart" title="Value Category" size="small" hoverable>
          <PieChart
            :valueTypeData="
              !isDemoOngoing ? valueAddedPieChart : stationDesignDummyObject.valueAddedPieChart
            "
          />
        </a-card>
      </a-col>
      <a-col span="24">
        <StepsTable />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useStationStore } from 'src/stores/station'
import { useClassificationStore } from 'src/stores/classification'
import { useSegmentationStore } from 'src/stores/segmentation'
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import { useToast } from 'vue-toastification'
import { downloadS3File } from 'src/utils/helpers'
import { isEmpty } from 'lodash-es'
import { useFileStore } from 'src/stores/file'
import { cyclesColumn, processStatus, downloadCycleLabels, downloadExcelMenuItems } from '../config'
import BarChart from './BarChart.vue'
import PieChart from './PieChart.vue'
import StepsTable from './StepsTable.vue'
import eventBus from 'src/utils/eventBus'
import { getDownloadExcelApiSD } from 'src/utils/excel'

const toast = useToast()
const environment = import.meta.env.MODE

export default {
  inject: ['nextTab', 'showError', 'showInfo'],
  setup: () => ({
    environment,
    downloadCycleLabels,
    downloadExcelMenuItems
  }),
  components: {
    BarChart,
    PieChart,
    StepsTable,
    DownOutlined,
    DownloadOutlined
  },

  data() {
    return {
      loading: true,
      showConfirmModal: false,
      cycleDownloadStaus: false,
      cycleDownloadLoading: false,
      downloadStatus: 'initial',
      isDownloading: false,
      isDownloadingInstruction: false
    }
  },

  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, [
      'newStudy',
      'newStudyId',
      'workCycles',
      'masterCycle',
      'studyVideoUrl',
      'selectedMasterCycleId',
      'stationDesignDummyObject'
    ]),
    ...mapState(useSegmentationStore, [
      'valueAddedPieChart',
      'stepsTimePerCycle',
      'cyclesTableData'
    ]),
    ...mapState(useClassificationStore, ['therbligs']),

    cyclesTableColumn() {
      let columns = cyclesColumn
      if (this.workCycles.length < 2) return columns
      const cycleTimeColumns = Array.from({ length: this.workCycles.length }).map((v, ix) => ({
        title: ix + 1,
        key: `cycle_time_${ix + 1}`,
        dataIndex: `cycle_time_${ix + 1}`,
        align: 'center',
        width: 60
      }))
      columns = columns.map((col) =>
        col.key === 'observedTime' ? { ...col, children: cycleTimeColumns } : col
      )
      return columns
    },

    tableScroll() {
      const max = 10
      // 900 -> other column width
      const contentWidth = 900 + 60 * this.workCycles.length
      return this.workCycles.length > max ? { x: `calc(${contentWidth}px)` } : {}
    }
  },

  watch: {
    masterCycle(cycle) {
      if (!cycle || isEmpty(cycle)) return
      const videoObj = cycle.video_file
      if (!videoObj) return
      const isProcessing = videoObj.process?.status?.name === processStatus['InProgress']
      if (isProcessing) {
        this.cycleDownloadLoading = true
        this.cycleDownloadStaus = setInterval(this.getCycleDownloadStatus, 5000)
        this.downloadStatus = 'processing'
      }
      // this.getCycleSegments(cycle.cycle_no)
    }
  },

  methods: {
    ...mapActions(useStationStore, [
      'fetchWorkCyles',
      'updateStudy',
      'createCycleVideo',
      'getCycleVideoData'
    ]),
    ...mapActions(useSegmentationStore, ['fetchAllSteps', 'getCycleSegments']),
    ...mapActions(useClassificationStore, ['getTherbligs']),
    ...mapActions(useFileStore, ['fetchPresignedUrl']),

    async getData() {
      let promises = []
      promises.push(this.fetchWorkCyles())
      promises.push(this.getTherbligs())
      await Promise.all(promises)
      await this.fetchAllSteps()
    },

    handleShowImprovementConfirmModal() {
      if (this.loading) return
      if (!this.newStudy.get_improvement) this.showConfirmModal = true
      else this.nextTab()
    },

    async handleConfirmImprovement() {
      await this.updateStudy({ get_improvement: true })
      this.nextTab()
    },

    async handleDownloadCycle() {
      if (!this.masterCycle) return
      let videoFile = this.masterCycle.video_file
      this.cycleDownloadLoading = true
      if (!videoFile) {
        await this.createCycleVideo(this.selectedMasterCycleId)
        this.cycleDownloadStaus = setInterval(this.getCycleDownloadStatus, 5000)
        this.downloadStatus = 'processing'
      } else await this.downloadCycleVideo(videoFile.location)
    },

    async getCycleDownloadStatus() {
      const cycleVideo = await this.getCycleVideoData(this.selectedMasterCycleId)
      const videoProcessComplete = cycleVideo?.status == processStatus['Finished']
      if (videoProcessComplete) {
        clearInterval(this.cycleDownloadStaus)
        await this.downloadCycleVideo(cycleVideo.location)
        await this.fetchWorkCyles()
      }
    },

    async downloadCycleVideo(videoLocation) {
      this.downloadStatus = 'downloading'
      const presignedUrl = await this.fetchPresignedUrl(videoLocation)
      await downloadS3File(presignedUrl, this.masterCycle.name)
      this.cycleDownloadLoading = false
      this.downloadStatus = 'initial'
    },

    async downloadExcelSheet(type) {
      this.isDownloading = true
      const apiFunction = getDownloadExcelApiSD(type.key)

      if (!apiFunction) {
        console.error('Invalid download type')
        this.isDownloading = false
        return
      }

      try {
        const [error, data] = await apiFunction(this.newStudyId)
        if (error || !data?.data) {
          throw new Error('Invalid response from API')
        } else await downloadS3File(data.data)
      } catch (error) {
        toast.error('Something went wrong, Please try later.')
      } finally {
        this.isDownloading = false
      }
    }
  },

  async created() {
    eventBus.$on('timeStudy-next-page', this.handleShowImprovementConfirmModal)
    await this.getData()
    this.loading = false
  },

  beforeUnmount() {
    clearInterval(this.cycleDownloadStaus)
    eventBus.$off('timeStudy-next-page', this.handleShowImprovementConfirmModal)
  }
}
</script>
